.sidebar {
  position: fixed;
  left: 0px;
  top: 84px;

  border-right: 1px solid #414143;

  max-width: 280px;
  width: 100%;
  z-index: 3;
  max-height: calc(100vh - 90px);
  height: calc(100vh - 90px);
  padding: 20px 20px;
  overflow-y: auto;
  overflow-x: hidden;
}
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
  height: 100%;
}
.logoContainer {
  display: flex;
  align-items: center;
  gap: 12px;

  padding-bottom: 20px;
  min-height: 65px;

  border-bottom: 1px solid #414143;
  display: none;
}

.logo {
  width: 32px;
}
.logoText {
  color: #dbdbdc;
  text-align: center;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 133.333%;
}

.navContainer {
  display: flex;
  flex-direction: column;
}
.title {
  color: #737377;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 133.333%;
  padding: 15px 0;
}
.navItems {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.navItem {
  color: rgba(255, 255, 255, 0.99);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 15px 15px;
}

.navActive {
  border-radius: 6px;
  border-top: 1px solid #555558;
  background: #414143;

  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.06),
    0px 1px 1px 0px rgba(0, 0, 0, 0.03);
}
.icon {
  width: 18.33px;
}
.downloadCsvContainer {
  border-radius: 8px;
  border-top: 1px solid #555558;
  background: #414143;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.06),
    0px 1px 1px 0px rgba(0, 0, 0, 0.03);
  padding: 18px;

  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: auto;
}
.monthlyReport {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
.button {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  display: flex;
  padding: 8px 14px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 6px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  background: #3e97ff;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.06),
    0px 1px 1px 0px rgba(0, 0, 0, 0.03);
}
.userContainer {
  display: flex;
  width: 240px;
  padding: 8px 12px 8px 8px;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  border-top: 1px solid var(--Base-200, #555558);
  background: var(--Base-300, #414143);

  /* Dropshadow/sm */
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.06),
    0px 1px 1px 0px rgba(0, 0, 0, 0.03);
  position: relative;
}

.userImage {
  width: 40px;

  border-radius: 50%;
}
.userName {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
.position {
  color: #3e97ff;

  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 142.857%;
}
.arrow {
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  margin-left: auto;
}
.dropDown {
  position: absolute;
  min-width: 100%;
  top: calc(100% + 3px);
  left: 0;
  padding: 12px;
  border-radius: 6px;
  border-top: 1px solid #555558;
  background: #414143;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.06),
    0px 1px 1px 0px rgba(0, 0, 0, 0.03);
}
.logoutButton {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  background: transparent;
  color: #ffffff;
  border: none;
  outline: none;
  cursor: pointer;
}
@media only screen and (max-width: 1199px) {
  .sidebar {
    transform: translateX(-150%);
    transition: 0.3s;
    left: 0;
    top: 0;
  }
  .showSidebar {
    transform: translateX(0%);
    background: rgba(55, 55, 57, 1);

    transition: 0.3s;
  }
  .logoContainer {
    display: flex;
  }
}

@media only screen and (max-width: 380px) {
  .sidebar {
    width: 100%;
  }
}
/* ===== Scrollbar CSS ===== */
/* Firefox */

.sidebar {
  scrollbar-width: thin;
  scrollbar-color: #fff #414143;
}
/* For Firefox */
/* For Firefox */

/* Chrome, Edge, and Safari */
.sidebar::-webkit-scrollbar {
  width: 3px;
}

.sidebar::-webkit-scrollbar-track {
  background: #414143;
}

.sidebar::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 100%;
  border: none;
}
