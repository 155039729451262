.wrapper {
  background: radial-gradient(
    45.37% 45.35% at 50.83% -0.05%,
    #18181b 0%,
    #0d0d10 100%
  );
  padding: 120px 0;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 70px;
}
.headingContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}
.heading {
  color: #e0e0e0;

  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 142.857%;
  letter-spacing: -0.04px;
  border-radius: 26px;
  border: 1px solid #fff;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.12) 100%
  );
  padding: 3px 10px;
}
.title {
  color: #e0e0e0;
  font-family: Abel;
  font-size: 33px;
  font-style: normal;
  font-weight: 400;
  line-height: 115.152%;
}

.details,
.info {
  color: #7e808a;

  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.04px;
}
.services {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 50px 35px;
}
.card {
  display: flex;
  flex-direction: column;

  gap: 5px;
}
.icon {
  max-width: 24px;
}
.cardTitle {
  color: #e0e0e0;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  letter-spacing: -0.04px;
  padding-top: 5px;
}

@media only screen and (max-width: 991px) {
  .services {
    grid-template-columns: repeat(3, 1fr);
    gap: 50px 25px;
  }
}
@media only screen and (max-width: 767px) {
  .container {
    justify-content: center;
    align-items: center;
    gap: 60px;
  }
  .headingContainer {
    align-items: center;
    gap: 7px;
  }

  .title,
  .details {
    text-align: center;
  }
  .services {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 520px) {
  .wrapper {
    padding: 70px 0;
  }
  .title {
    font-size: 25px;
  }
  .details {
    font-size: 14px;
  }
  .services {
    grid-template-columns: repeat(1, 1fr);
    gap: 40px 25px;
  }
}
