.mainWrapper {
  background: rgba(55, 55, 57, 1);
  min-height: 100vh;
}

.wrapper {
  padding-left: calc(280px + 40px);
  padding-right: calc(281px + 40px);
  padding-bottom: 20px;

  width: 100%;
}
.container {
  margin-top: 120px;
  max-width: 1150px;
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
@media only screen and (max-width: 1199px) {
  .wrapper {
    padding: 0 0px;
  }
  .container {
    width: 90%;
    max-width: 800px;
  }
}
