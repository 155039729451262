.mainWrapper {
  display: flex;
  align-items: center;
  position: fixed;

  width: 100%;
  top: 0;
  z-index: 3;
  height: 84px;
  padding: 20px 0;
  padding-right: 20px;
  border-bottom: 1px solid #414143;
}
.mainContainer {
  max-width: 1500px;
  width: 100%;
  margin: 0 auto;
}
.fixedNavbar {
  background: #414143;
}

.wrapper {
  display: flex;
  align-items: center;

  gap: 24px;
  width: 100%;
}

.logoContainer {
  display: flex;
  align-items: center;
  gap: 12px;
  border-right: 1px solid #414143;
  min-width: 280px;
  padding: 24px 0;
  padding-left: 20px;
}

.logo {
  width: 32px;
}
.logoText {
  color: #dbdbdc;
  text-align: center;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 133.333%;
}
.rightBar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
}
.searchContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  border-bottom: 1px solid #555558;
  background: #414143;

  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  max-width: 340px;
  width: 100%;
  padding: 10px 10px 10px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
}
.searchIcon {
  color: #737377;
  font-size: 24px;
  min-width: 24px;
}
.input {
  color: rgba(255, 255, 255, 0.99);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  width: 100%;
}
.controlS {
  color: rgba(255, 255, 255, 0.99);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 133.333%;
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid #555558;
  background: #414143;
  white-space: nowrap;
  cursor: pointer;
}
.buttonContainer {
  display: flex;
  align-items: center;
  gap: 18px;
  margin-left: auto;
}

.button {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 6px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  background: #3e97ff;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.06),
    0px 1px 1px 0px rgba(0, 0, 0, 0.03);
}
.icon {
  color: #a7a7aa;
  font-size: 27px;
  min-width: 27px;
  cursor: pointer;
}
.hamburger,
.mobileHamburger {
  display: none;
  color: #dbdbdc;
  font-size: 32px;

  cursor: pointer;
}
.sidebarArrow {
  color: #e0e0e0;
  position: fixed;
  top: 90px;
  left: 0;
  font-size: 30px;
  z-index: 4;

  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  display: none;
}
@media only screen and (max-width: 1199px) {
  .mainWrapper {
    padding-left: 15px;
    padding-right: 15px;
  }
  .logoContainer {
    display: none;
  }
  .buttonContainer {
    gap: 14px;
  }
  .button {
    font-size: 14px;
    padding-left: 18px;
    padding-right: 18px;
  }
  .hamburger {
    display: block;
  }
  .sidebarArrow {
    display: block;
  }
}
@media only screen and (max-width: 767px) {
  .mainWrapper {
    height: auto;
  }
  .rightBar {
    flex-direction: column-reverse;
  }
}
@media only screen and (max-width: 520px) {
  .logoContainer {
    width: 100%;
  }

  .wrapper {
    flex-direction: column;
  }
  .sidebarArrow {
    top: 210px;
  }
}
@media only screen and (max-width: 400px) {
  .buttonContainer {
    gap: 10px;
  }
  .hamburger {
    font-size: 26px;
  }
  .icon {
    font-size: 24px;
  }
  .button {
    padding: 8px 12px;
  }
}
