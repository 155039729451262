.wrapper {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: transparent;
  transition: 0.3s;
  background: #0d0d10;
}
.wrapperBg {
  background: #0d0d10;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  padding: 20px 0;
}
.logoContainer {
  display: flex;
  align-items: center;
  gap: 18px;
}
.logo {
  width: 135px;
}
.logoText {
  color: #e0e0e0;
  font-family: Abel;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 142.857%;
  letter-spacing: -0.04px;
}
.navItemsAndButton {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.navItems {
  display: flex;
  align-items: center;
  gap: 30px;
}
.navItem {
  color: rgba(248, 248, 248, 0.49);

  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 162.5%;
  cursor: pointer;
  text-transform: capitalize;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
}
.iconContainer {
  display: none;
}

.button {
  color: rgba(248, 248, 248, 0.69);

  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 142.857%;
  padding: 10px 18px;

  border-radius: 4px;
  border: 1px solid var(--White-alpha-A04, rgba(248, 248, 248, 0.09));
  background: var(--White-alpha-A01, rgba(248, 248, 248, 0.02));
  white-space: nowrap;
}
.button:hover {
  background: #fff;
  color: #0d0d10;
  border: 1px solid #0d0d10;
}
@media only screen and (max-width: 767px) {
  .navItemsAndButton {
    justify-content: flex-end;
  }
  .navItems {
    flex-direction: column;
    gap: 40px;
    min-height: 100vh;
    background: radial-gradient(
      45.37% 45.35% at 50.83% -0.05%,
      #18181b 0%,
      #0d0d10 100%
    );
    transform: translateX(250%);
    transition: 0.3s;
    position: fixed;
    top: 80px;
    right: 0;
    padding-top: 50px;
    max-width: 320px;
    width: 100%;
    min-height: 100vh;
  }
  .sidebar {
    transform: translateX(0);
    right: 0;
  }
  .buttonContainer {
    align-items: center;
    gap: 15px;
  }
  .button {
    font-size: 14px;
    padding: 10px 15px;
  }
  .iconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid var(--White-alpha-A04, rgba(248, 248, 248, 0.09));
    background: var(--White-alpha-A01, rgba(248, 248, 248, 0.02));
  }

  .icon {
    color: #fff;
    font-size: 24px;
  }
}
@media only screen and (max-width: 420px) {
  .header {
    gap: 25px;
  }
  .logoContainer {
    gap: 10px;
  }
  .logo {
    width: 100px;
  }
  .navItems {
    top: 65px;
  }
  .navItem {
    font-size: 14px;
  }
  .buttonContainer {
    gap: 10px;
  }
  .button {
    padding: 8px 10px;
    font-size: 12px;
  }
  .iconContainer {
    width: 36px;
    height: 36px;
  }

  .icon {
    font-size: 22px;
  }
}
