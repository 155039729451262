.wrapper {
  background: radial-gradient(
    45.37% 45.35% at 50.83% -0.05%,
    #18181b 0%,
    #0d0d10 100%
  );
  padding: 120px 0;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.headingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}
.heading {
  color: #e0e0e0;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 142.857%;
  letter-spacing: -0.04px;
  border-radius: 26px;
  border: 1px solid #fff;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.12) 100%
  );
  padding: 3px 10px;
}
.title {
  color: #e0e0e0;
  font-family: Abel;
  font-size: 33px;
  font-style: normal;
  font-weight: 400;
  line-height: 115.152%;
}
.faqWrapper {
  max-width: 690px;
  width: 90%;
  margin: 0 auto;
}
.questionWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  cursor: pointer;
}

.question {
  color: #e9e9e9;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 144.444%;
  letter-spacing: -0.04px;
}
.icon {
  color: #fff;
  font-size: 24px;
  min-width: 25px;
  cursor: pointer;
  margin-left: 15px;
  margin-top: 3px;
}

.answer {
  font-size: 16px;
  font-weight: 400;
  padding-top: 20px;
}
.faqContainer {
  border-radius: 16px;
  border: 1px solid var(--White-alpha-A03, rgba(248, 248, 248, 0.05));
  background: var(--White-alpha-A02, rgba(248, 248, 248, 0.03));
  box-shadow: 0px 99px 129px -81px rgba(0, 0, 0, 0.3);
  margin: 18px 0;
  padding: 15px;
}
.activeFaqContainer {
  /* border-radius: var(--borderRadius);
  border: 1px solid var(--borderColor);
  background: var(--primaryColor); */
  margin: 18px 0;
  padding: 15px;
}

@media only screen and (max-width: 575px) {
  .question {
    font-size: 14px;
  }
  .icon {
    font-size: 18px;
    min-width: 18px;
    cursor: pointer;
  }
  .question {
    font-size: 15px;
  }
  .answer {
    font-size: 15px;
    padding-top: 12px;
  }
}
@media only screen and (max-width: 520px) {
  .wrapper {
    padding: 70px 0;
  }
  .title {
    font-size: 25px;
  }
}
