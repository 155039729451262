.mainWrapper {
  background: rgba(55, 55, 57, 1);
  min-height: 100vh;
  padding: 70px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 520px) {
  .mainWrapper {
    padding: 50px 0;
  }
}
