.wrapper {
  max-width: 450px;
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.headingContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-width: 384px;
  margin: 0 auto;
}
.heading {
  color: #fff;
  text-align: center;

  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 133.333%;
}
.tagline {
  color: #a7a7aa;
  text-align: center;

  /* text-base/Regular */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.buttonContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.signupWithButton {
  color: #dbdbdc;
  text-align: center;
  white-space: nowrap;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 6px;
  border-top: 1px solid #555558;
  background: #414143;
  width: calc(50% - 5px);

  /* Dropshadow/sm */
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.06),
    0px 1px 1px 0px rgba(0, 0, 0, 0.03);
  padding: 10px 18px;
  width: 100%;
}
.googleIcon,
.appleIcon {
  min-width: 20px;
  font-size: 20px;
}
.appleIcon {
  color: #fff;
}
.signWithEmailContainer {
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1;
}
.signWithEmail {
  color: #737377;
  font-family: Inter;
  font-size: 14px;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  line-height: 133.333%;
  background: rgba(55, 55, 57, 1);
  padding: 0 15px;
}
.signWithEmailContainer::before {
  content: " ";
  position: absolute;
  width: 100%;
  height: 2px;
  background: #414143;
  z-index: -1;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.formContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.inputContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 12px 12px 16px;
  border-radius: 6px;
  border-bottom: 1px solid #555558;
  background: #414143;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
}
.input {
  color: #dbdbdc;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  width: 100%;
}
.icon {
  font-size: 24px;
  color: rgba(115, 115, 119, 1);
}
.forgotPassword {
  color: #3e97ff;
  text-align: right;

  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 142.857%;
  margin-top: -8px;
}
.submitButton,
.backToSignin {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 6px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  background: var(--Main-Primary, #3e97ff);

  /* Dropshadow/sm */
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.06),
    0px 1px 1px 0px rgba(0, 0, 0, 0.03);
}
.backToSignin {
  width: auto;
  margin: 0 auto;
}
.alreadyHaveAccount,
.doNotHaveAccount,
.allreadyResetPassword {
  color: #737377;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 142.857%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.link {
  color: #3e97ff;
  font-weight: 500;
}
@media only screen and (max-width: 520px) {
  .heading {
    font-size: 24px;
  }
  .buttonContainer {
    grid-template-columns: 1fr;
  }
  .alreadyHaveAccount,
  .doNotHaveAccount,
  .allreadyResetPassword,
  .forgotPassword {
    font-size: 14px;
  }
}
