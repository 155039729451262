.wrapper {
  border-radius: var(--Radius-5-max, 12px);
  border-radius: 12px;
  border: 1px solid rgba(248, 248, 248, 0.1);
  background: var(
    --Gradient-linear,
    linear-gradient(24deg, #18181d 30.95%, #0d0d10 83.96%)
  );

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 30px;

  width: 100%;
  height: 100%;
}
.headingContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;

  padding: 18px;
  padding-bottom: 0;
}
.title {
  color: #e0e0e0;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 144.444%;
  letter-spacing: -0.04px;
}
.info {
  color: #7e808a;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 142.857%;
  letter-spacing: -0.04px;
}
.imgAndButton {
  background: url(../../../../images/historyDataPattern.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.buttonContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
  padding: 0 4px;
  padding-bottom: 18px;
}
.button {
  color: #e0e0e0;

  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 133.333%;
  letter-spacing: -0.04px;
  border-radius: 68px;
  border: 1px solid #f8f8f8;
  background: var(--White-alpha-A03, rgba(248, 248, 248, 0.05));

  backdrop-filter: blur(30px);
  padding: 5px 7px;
}
.button:hover {
  transform: none;
}

.image {
  display: block;
  width: 80%;
  margin: 0 auto;
  margin-top: auto;
}
@media only screen and (max-width: 767px) {
  .button {
    font-size: 11px;
  }
}
